<style>
.bg-readonly input, .bg-readonly textarea {
    background-color: rgb(235, 235, 235);
}
</style>
<template>
    <vx-card :title="(this.id ? 'Edit' : 'Create') + ' GR Simple Stock Transfer Order'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>STO Document Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.sto_code" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>GR [STO] Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.gr_code" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.src_wh" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Destination Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.dest_wh" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Delivery Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.delivery_date" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Receive Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date v-if="this.data.status == 0" :value="this.data.receive_date" @selected="(val) => {
                    this.data.receive_date = val
                }"></Date>
                 <vs-input v-else :value="data.receive_date" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea :readonly="statusReadonly" v-model="data.note" width="100%" :class="statusReadonly ? 'bg-readonly' : ''"/>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="data.status == 0">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Attachment</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <input type="file" ref="attechment" accept=".jpg,.jpeg, .png" @change="handleChangeAttachment" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="this.fileUrl != ''">
            <div class="vx-col sm:w-1/3 w-full flex items-center">

            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <div class="op-block mt-5">
                    <vs-list>
                        <vs-list-item title="File 1">
                            <div class="vx-input-group flex">
                                <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                    title="Download" class="mr-1" @click="openTab(fileUrl)" />
                                <!-- <vs-button size="small" color="danger" icon-pack="feather" icon="icon-trash"
                                    title="Delete" /> -->
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full mb-6">
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="15%">SKU Code</th>
                            <th width="30%">SKU Description</th>
                            <th width="20%">HU</th>
                            <th width="15%">Qty DO</th>
                            <th width="15%">Qty Receive</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(line, index) in lines" :key="index" style="padding-bottom;: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="line.sku_code" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="line.item_name" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="line.unit" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="line.qty_do" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" :readonly="statusReadonly" v-model="line.qty_receive" :class="statusReadonly ? 'bg-readonly' : ''"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div v-if="data.status == 0"
                style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button v-if="data.gr_id == null" class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button v-else class="mr-3 mb-2" @click="handleSubmit">Update</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>

import _ from "lodash";
import Warehouse from "@/components/Warehouse.vue";
import Date from "@/components/Date.vue";
import moment from "moment";

import vSelect from "vue-select";
export default {
    components: {
        Warehouse,
        Date,
        vSelect
    },
    data() {
        return {
            data: {
                delivery_date: null,
                dest_wh: null,
                dest_wh_code: null,
                dest_wh_id: null,
                dest_wh_name: null,
                do_code: null,
                do_id: null,
                gr_code: null,
                gr_id: null,
                note: null,
                receive_date: null,
                src_wh: null,
                src_wh_code: null,
                src_wh_id: null,
                src_wh_name: null,
                status_receive: null,
                sto_code: null,
                sto_id: null,
                file: null,
                status: null,
                fileUrl: '',
            },
            lines: [],
            statusReadonly: false,
        }
    },
    mounted() {
        // var now = moment()
        // this.data.receive_date = now
        // now.add(1, 'days')

        this.id = this.$route.params.id;
        if (this.id) { // sto id
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.$store.state.inbound.simpleSto.baseUrlPath + "/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        var now = moment().toDate();
                        this.data.delivery_date = resp.data.delivery_date
                        this.data.dest_wh = resp.data.dest_wh_code + " " + resp.data.dest_wh_name
                        this.data.dest_wh_code = resp.data.dest_wh_code
                        this.data.dest_wh_id = resp.data.dest_wh_id
                        this.data.dest_wh_name = resp.data.dest_wh_name
                        this.data.do_code = resp.data.do_code
                        this.data.do_id = resp.data.do_id
                        this.data.gr_code = resp.data.gr_code
                        this.data.gr_id = resp.data.gr_id
                        this.data.receive_date = resp.data.receive_date == null ? now : resp.data.receive_date
                        this.data.src_wh = resp.data.src_wh_code + " " + resp.data.src_wh_name
                        this.data.src_wh_code = resp.data.src_wh_code
                        this.data.src_wh_id = resp.data.src_wh_id
                        this.data.src_wh_name = resp.data.src_wh_name
                        this.data.status_receive = resp.data.status_receive
                        this.data.sto_code = resp.data.sto_code
                        this.data.sto_id = resp.data.sto_id
                        this.id = resp.data.sto_id
                        this.data.note = resp.data.note
                        this.data.status = resp.data.status
                        this.lines = resp.data.lines
                        this.fileUrl = resp.data.file_url

                        if (resp.data.status == 0){
                            this.statusReadonly = false
                        } else {
                            this.statusReadonly = true
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.inbound.simpleSto.baseRouterName,
            });
        },
        handleChangeAttachment(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        handleSubmit() {
            this.data.dlv_date = moment(this.data.delivery_date).format('YYYY-MM-DD')
            this.data.rcv_date = moment(this.data.receive_date).format('YYYY-MM-DD')
            let message = ""
            this.lines.forEach((val, i) => {
                if (val.qty_receive == null || val.qty_receive == 0 || val.qty_receive == "") {
                    message = "please check qty receive"
                    return
                } else {
                    this.lines[i].qty_receive = parseInt(val.qty_receive)
                }
            })

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("data", JSON.stringify(this.data))
            formData.append("lines", JSON.stringify(this.lines))
            this.$vs.loading();
            let http = null
            if (this.data.gr_id != null) {
                http = this.$http.put(this.$store.state.inbound.simpleSto.baseUrlPath + "/" + this.data.gr_id, formData)
            } else {
                http = this.$http.post(this.$store.state.inbound.simpleSto.baseUrlPath, formData)
            }

            http.then((resp) => {
                console.log(resp)
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });

                    this.$vs.loading.close();
                    this.draw++;
                    this.handleBack()
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            })
            //     .catch((error) => {
            //         this.$vs.loading.close();
            //         console.log(error);
            //     });

        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    }
}
</script>